<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <div class="row">
                <div class="col-4">
                    <ValidationProvider name="signers" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('full_name')">
                            <staff-auto-complete-list 
                                ref="staffAutoCompleteList"
                                v-model="form.signers"
                                :options="signerOptions"
                                :valueArray="form.signers"
                                :keywordOnly="true"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider name="title" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('title2')">
                            <b-form-input v-model="form.title" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider name="rank" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('rank')">
                            <b-form-input v-model="form.rank" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-2 d-flex align-items-center">
                    <b-button
                        @click="addSigner"
                        :disabled="!form.signers.length || !form.title || !form.rank"
                        variant="success"
                        class="ml-2"
                    >
                        +
                    </b-button>
                </div>
            </div>
            <div class="mt-3">
                <b-table :items="signerList" :fields="signerFields" v-if="signerList.length > 0">
                    <template #cell(action)="data">
                        <b-button
                            variant="danger"
                            size="sm"
                            @click="removeSigner(data.item)"
                        >
                            -
                        </b-button>
                    </template>
                </b-table>
            </div>
        </ValidationObserver>

        <div class="col-6 mt-3 d-flex">
            <b-button
                @click="updateForm"
                type="button"
                :disabled="formLoading"
                variant="primary"
                class="btn-lg mr-2"
            >
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
import StaffAutoCompleteList from "@/components/interactive-fields/StaffAutoCompleteList";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CertificateCenterCertificateService from "@/services/CertificateCenterCertificateService";

export default {
    components: {
        StaffAutoCompleteList,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        formId: {
            type: Number,
        },
    },
    data() {
        return {
            formLoading: false,
            form: {
                signers: [],
                title: '',
                rank: ''
            },
            signerOptions: [],
            signerList: [],
            signerFields: [
                { key: 'fullName', label: this.$t('full_name') },
                { key: 'title', label: this.$t('title2') },
                { key: 'rank', label: this.$t('rank') },
                { key: 'action', label: '' }
            ],
        };
    },
    watch: {
        formId: function (val) {
            this.get(val);
        },
    },
    async created() {
        await this.get(this.formId);
    },
    methods: {
        get(id) {
            this.formLoading = true;
            CertificateCenterCertificateService.get(id)
                .then((response) => {
                    const data = response.data.data;
                    this.form = data;

                    if (data.signers && data.signers.length > 0) {
                        this.signerList = data.signers.map((signer) => ({
                            user_id: signer.user_id,
                            fullName: `${signer.name} ${signer.surname}`,
                            title: signer.title,
                            rank: signer.rank,
                        }));
                    }

                    this.form.signers = data.signers || [];
                    this.signerOptions = this.form.signers;
                    this.formLoading = false;
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    if (error.data.message) {
                        this.$toast.error(this.$t("api." + error.data.message));
                    }
                });
        },
        addSigner() {
            if (this.form.signers.length && this.form.title && this.form.rank) {
                const selectedSigner = this.form.signers[0];

                const signerToAdd = {
                    user_id: this.form.signers[0].value,
                    fullName: `${selectedSigner.text} ${selectedSigner.surname || ''}`,
                    title: this.form.title,
                    rank: this.form.rank,
                };

                this.signerList.push(signerToAdd);

                this.form.signers = [];
                this.form.title = '';
                this.form.rank = '';
                this.$refs.staffAutoCompleteList.selectedList = []
            }
        },
        removeSigner(signer) {
            this.signerList = this.signerList.filter(item => item.user_id !== signer.user_id);
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                this.formLoading = true;

                const signersToSend = this.signerList.map(item => ({
                    user_id: item.user_id,
                    title: item.title,
                    rank: item.rank,
                }));

                const formData = {
                    ...this.form,
                    signers: signersToSend
                };

                CertificateCenterCertificateService.signerUpdate(this.formId, formData)
                    .then((response) => {
                        this.formLoading = false;
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.$emit("updateSignerSuccess");
                    })
                    .catch((error) => {
                        this.showErrors(error, this.$refs.formModalValidate);
                    });
            }
        },
    },
};
</script>
