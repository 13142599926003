<template>
    <div>
        <div>
            <multiselect
                v-model="searchInput"
                label="text"
                track-by="value"
                :disabled="disabled"
                open-direction="bottom"
                :placeholder="$t('search')"
                :class="validateError != '' ? 'box-border-color' : ''"
                :options="options"
                :searchable="true"
                :loading="isLoading"
                :internal-search="false"
                :close-on-select="!multiple"
                :clear-on-select="!multiple"
                :multiple="false" 
                :show-no-results="true"
                @search-change="search"
                @input="handleInput"
            >
                <template v-if="selectedList.length === 1 && keywordOnly === true" slot="singleLabel" slot-scope="{ option }">
                    {{ selectedList[0].text }}
                </template>

                <template v-else slot="singleLabel" slot-scope="{ option }">
                    {{ option.text }}
                </template>
                <span slot="noOptions"></span>
                <span slot="noResult">{{ $t('no_result') }}</span>
            </multiselect>
        </div>
        <div v-if="!keywordOnly" class="selected-items">
            <span v-for="item in selectedList" :key="item.value" class="selected-item" @click="removeFromSelectedList(item)">
                <span>X</span> {{ item.text }}
            </span>
        </div>

        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError" />
    </div>
</template>

<script>
// Helpers
import translateNSelected from "@/helpers/translateNSelected";
import handleInput from "@/helpers/handleInput";
import setSelected from '@/helpers/setSelected';

// Services
import StaffService from '@/services/StaffService';

export default {
    component:{
        translateNSelected,
        handleInput
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            default: null
        },
        valueObject: {
            type: Array,
            default: () => []
        },
        validateError: {
            type: String,
            default: ''
        },
        returnType: {
            type: String,
            default: 'id'
        },
        returnValue: {
            type: String,
            default: 'user_id'
        },
        multiple: {
            default: false,
        },
        keywordOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            options: [],
            searchInput: '',
            selectedList: [],
        }
    },
    watch: {
        valueObject: {
            deep: true,
            handler(newValue) {
                this.selectedList=newValue
            }
        }
    },
    methods: {
        setSelected: setSelected,

        handleInput(value) {
            const selectedOption = this.options.find(option => option.value === value.value);
            if (selectedOption && !this.selectedList.some(item => item.value === selectedOption.value)) {
                this.selectedList.push(selectedOption)
                this.$emit('input', this.selectedList)
            }

            this.searchInput = selectedOption ? selectedOption.text : '';

            if (this.searchInput.length > 2) {
                this.search(this.searchInput)
            }
        },
        removeFromSelectedList(item) {
            this.selectedList = this.selectedList.filter(i => i.value !== item.value);
            this.$emit('input', this.selectedList);
        },
        search(key) {
            if (key.length > 2) {
                const config = { params: { key: key } }
                this.isLoading = true
                StaffService.search(config)
                    .then((response) => {
                        let data = response.data.data;
                        this.options = []
                        data.forEach((item) => {
                            this.options.push({
                                value: item[this.returnValue] ? item[this.returnValue] : item.user_id,
                                text: item.name + ' ' + item.surname
                            });
                        });
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        }
    }
}
</script>

<style scoped>
.selected-items {
    margin-top: 10px;
}
.selected-item {
    display: inline-block;
    background-color: #e0e0e0;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
    
}
</style>
