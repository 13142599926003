<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <div class="row">
                <div class="col-4">
                    <ValidationProvider name="other_signers" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('full_name')">
                            <b-form-input v-model="form.other_signers" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider name="title" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('title2')">
                            <b-form-input v-model="form.title" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider name="rank" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('rank')">
                            <b-form-input v-model="form.rank" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-2 d-flex align-items-center">
                    <b-button
                        @click="addOtherSigner"
                        :disabled="!form.other_signers.length || !form.title || !form.rank"
                        variant="success"
                        class="ml-2"
                    >
                        +
                    </b-button>
                </div>
            </div>
            <div class="mt-3">
                <b-table :items="otherSignerList" :fields="otherSignerFields" v-if="otherSignerList.length > 0">
                    <template #cell(action)="data">
                        <b-button
                            variant="danger"
                            size="sm"
                            @click="removeOtherSigner(data.item)"
                        >
                            -
                        </b-button>
                    </template>
                </b-table>
            </div>
        </ValidationObserver>

        <div class="col-6 mt-3 d-flex">
            <b-button
                @click="updateForm"
                type="button"
                :disabled="formLoading"
                variant="primary"
                class="btn-lg mr-2"
            >
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CertificateCenterCertificateService from "@/services/CertificateCenterCertificateService";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        formId: {
            type: Number,
        },
    },
    data() {
        return {
            formLoading: false,
            form: {
                other_signers: '',
                title: '',
                rank: ''
            },
            otherSignerList: [],
            otherSignerFields: [
                { key: 'name', label: this.$t('full_name') },
                { key: 'title', label: this.$t('title2') },
                { key: 'rank', label: this.$t('rank') },
                { key: 'action', label: '' }
            ],
        };
    },
    watch: {
        formId: function (val) {
            this.get(val);
        },
    },
    async created() {
        await this.get(this.formId);
    },
    methods: {
        get(id) {
            this.formLoading = true;
            CertificateCenterCertificateService.get(id)
                .then((response) => {
                    const data = response.data.data;
                    this.form = {
                        other_signers: '', 
                        title: '',
                        rank: ''
                    };

                    if (data.other_signers && data.other_signers.length > 0) {
                        this.otherSignerList = data.other_signers.map((other_signer) => ({
                            name: other_signer.name,
                            title: other_signer.title,
                            rank: other_signer.rank,
                        }));
                    }

                    this.formLoading = false;
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    if (error.data.message) {
                        this.$toast.error(this.$t("api." + error.data.message));
                    }
                });
        },
        addOtherSigner() {
            if (this.form.other_signers.length && this.form.title && this.form.rank) {

                const otherSignerToAdd = {
                    name: this.form.other_signers,
                    title: this.form.title,
                    rank: this.form.rank,
                };

                this.otherSignerList.push(otherSignerToAdd);

                this.form.other_signers = '';
                this.form.title = '';
                this.form.rank = '';
            }
        },
        removeOtherSigner(other_signer) {
            this.otherSignerList = this.otherSignerList.filter(item => item.name !== other_signer.name);
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                this.formLoading = true;

                const otherSignersToSend = this.otherSignerList.map(item => ({
                    name: item.name,
                    title: item.title,
                    rank: item.rank,
                }));

                const formData = {
                    other_signers: otherSignersToSend
                };

                CertificateCenterCertificateService.otherSignerUpdate(this.formId, formData)
                    .then((response) => {
                        this.formLoading = false;
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.$emit("updateOtherSignerSuccess");
                    })
                    .catch((error) => {
                        this.showErrors(error, this.$refs.formModalValidate);
                    });
            }
        },
    },
};
</script>
