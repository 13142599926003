<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <div class="row">
                <div class="col-6">
                    <ValidationProvider name="certificate_center_id" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('certificate_center_id')">
                            <certificate-center-selectbox v-model="form.certificate_center_id" :validateError="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('certificates_name')">
                            <b-form-input v-model="form.name" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="start_date" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('start_date')">
                            <b-form-input type="date" v-model="form.start_date" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="end_date" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('end_date')">
                            <b-form-input type="date" v-model="form.end_date" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="document_type" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('document_type')">
                            <parameter-selectbox v-model="form.document_type" :code="'certificate_document_types'" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="document_template" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('document_draft')">
                            <parameter-selectbox v-model="form.document_template" :code="'certificate_templates'" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="instructors" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('instructors')">
                            <staff-auto-complete-list v-model="form.instructors" :multiple="true"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider name="has_teams_integration" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('has_teams_integration')">
                            <b-form-select v-model="form.has_teams_integration" :options="statusOptions" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider name="allow_epayment" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('allow_epayment')">
                            <b-form-select v-model="form.allow_epayment" :options="statusOptions" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <ValidationProvider name="allow_vinov" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('allow_vinov')">
                            <b-form-select v-model="form.allow_vinov" :options="statusOptions" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="content" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('content')">
                            <ckeditor v-model="form.content"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>

        <div class="col-6 mt-3 d-flex">
            <b-button
                @click="createForm"
                type="button"
                :disabled="formLoading"
                variant="primary"
                class="btn-lg mr-2"
            >
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
    //Component
    import CertificateCenterSelectbox from '@/components/interactive-fields/CertificateCenterSelectbox'
    import StaffAutoCompleteList from "@/components/interactive-fields/StaffAutoCompleteList";
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";

    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import CertificateCenterCertificateService from "@/services/CertificateCenterCertificateService";

    export default {
        components: {
            CertificateCenterSelectbox, 
            StaffAutoCompleteList,
            ParameterSelectbox,
            
            ValidationProvider, 
            ValidationObserver,
        },
        data() {
            return {
                formLoading: false,
                form: {
                    certificate_center_id: null,
                    name: null,
                    start_date: null,
                    end_date: null,
                    document_type: null,
                    document_template: null,
                    content: null,
                    instructors: [],
                    has_teams_integration: null,
                    allow_epayment: null,
                    allow_vinov: null,
                },
                statusOptions: [
                    {value: 1, text: this.$t('there_is')},
                    {value: 0, text: this.$t('absent')}
                ],
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate()
                if(this.form.instructors.length){
                  this.form.instructors=this.form.instructors.map(item=>{
                    return item.value
                  })
                }
                if (isValid) {
                    this.formLoading = true;
                    CertificateCenterCertificateService.store(this.form)
                                    .then((response) => {
                                        this.$toast.success(this.$t("api." + response.data.message));
                                        this.$emit("createFormSuccess")
                                    })
                                    .catch((error) => {
                                        this.showErrors(error, this.$refs.formModalValidate)
                                    }).finally(() => {
                        this.formLoading = false;
                    });
                }
            }
        }
    }
</script>
