<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <div class="row">
                <div class="col-5">
                    <ValidationProvider name="logos" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('logo1')">
                                                    <div class="d-flex custom-file-upload">
                                                        <b-form-file
                                                            v-model="form.logos"
                                                            :state="errors[0] ? false : null"
                                                            @change="previewLogo"
                                                            :placeholder="$t('select_file')"
                                                            ref="fileInput"
                                                            ></b-form-file>
                                                        <b-button variant="outline-secondary"
                                                                  @click="$refs.fileInput.$el.childNodes[0].click();">
                                                            {{ $t('browse') }}
                                                        </b-button>
                                                    </div>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]"
                                                         v-html="errors[0]"></div>
                                                </b-form-group>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-5">
                    <ValidationProvider name="rank" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('rank')">
                            <b-form-input v-model="form.rank" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-2 d-flex align-items-center">
                    <b-button
                        @click="updateForm"
                        :disabled="!form.logos || !form.rank"
                        variant="success"
                        class="ml-2"
                    >
                        +
                    </b-button>
                </div>
            </div>
            <img v-if="previewImage" :src="previewImage" alt="Preview" class="mt-2" style="max-width: 100px; max-height: 100px;" />
            <div class="mt-3">
                <b-table :items="computedList" :fields="logoFields" v-if="logoList.length > 0">
                    <template #cell(file)="data" v-if="checkPermission('certificatecentercertificate_downloadlogo')">
                        <b-button variant="link" @click="downloadLogo(data.item.file, data.item.name)" class="p-0">
                            {{ data.item.name  }}
                        </b-button>
                    </template>
                    <template #cell(action)="data" v-if="checkPermission('certificatecentercertificate_deletelogo')">
                        <b-button
                            variant="danger"
                            size="sm"
                            @click="removeLogo(data.item)"
                        >
                            -
                        </b-button>
                    </template>
                    <template #cell(base64data)="data">
                        <b-img v-if="data.item.base64data" :src="data.item.base64data" thumbnail fluid style="width: 60px" alt="image" class="border rounded" />
                    </template>
                </b-table>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CertificateCenterCertificateService from "@/services/CertificateCenterCertificateService";
import toBase64 from '@/helpers/toBase64'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        formId: {
            type: Number,
        },
    },
    data() {
        return {
            formLoading: false,
            form: {},
            certicate_id:null,
            logoList: [],
            previewImage: null,
            logoFields: [
                {key: 'base64data', label: ''},
                { key: 'file', label: this.$t('logo1') },
                { key: 'rank', label: this.$t('rank') },
                { key: 'action', label: '' }
            ],
            bufferSrc: null
        };
    },
    computed: {
        computedList(){
            this.logoList.forEach((itm, index) => {
                CertificateCenterCertificateService.downloadLogo(itm.file).then((response) => {
                    this.logoList[index].base64data = this.toBase64(response)
                }).catch(e => {
                    this.showErrors(e)
                })
            })
            return this.logoList
        }
    },
    watch: {
        formId: function (val) {
            this.get(val);
        },
    },
    async created() {
        await this.get(this.formId);
    },
    methods: {
        toBase64: toBase64,
        previewLogo(event) {
            const file = event.target.files[0];
            if (file) {
                this.previewImage = URL.createObjectURL(file);
            }
        },
        get(id) {
            this.formLoading = true;
            CertificateCenterCertificateService.get(id)
                .then((response) => {
                    const data = response.data.data;
                    if (data.logos && data.logos.length > 0) {
                        this.logoList = data.logos.map((logo) => ({
                            file: logo.uuid,
                            rank: logo.custom_properties.rank,
                            name: logo.file_name,
                            base64data: null
                        }));
                    }
                    this.certicate_id=data.certificate_center_id
                    this.formLoading = false;
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    if (error.data.message) {
                        this.$toast.error(this.$t("api." + error.data.message));
                    }
                });
        },
        removeLogo(logo) {
            const data = {
                rank: logo.rank
            }
            CertificateCenterCertificateService.deleteLogo(this.certicate_id,data)
                .then((response) => {
                    this.logoList = this.logoList.filter(item => item.rank !== logo.rank);
                    this.$toast.success(this.$t("api." + response.data.message));
                })
                .catch((error) => {
                    this.showErrors(error, this.$refs.formModalValidate);
                });
        },
        downloadLogo(fileUuid, fileName) {
            CertificateCenterCertificateService.downloadLogo(fileUuid)
                .then((response) => {
                    const blob = new Blob([response.data], { type: response.data.type });
                    const url = window.URL.createObjectURL(blob);
                
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                
                    setTimeout(() => {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                
                    this.$toast.success(this.$t("api." + response.data.message));
                })
                .catch((error) => {
                    this.showErrors(error, this.$refs.formModalValidate);
                });
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                this.formLoading = true;

                let formData = new FormData();

                formData.append('file', this.form.logos);
                formData.append('rank', this.form.rank);

                CertificateCenterCertificateService.addLogo(this.formId, formData)
                    .then((response) => {
                        this.formLoading = false;
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.get(this.formId)
                    })
                    .catch((error) => {
                        this.showErrors(error, this.$refs.formModalValidate);
                    });
            }
        },
    },
};
</script>
